import { createSlice } from '@reduxjs/toolkit';
import { getInvoiceDetails } from '../../api/api';

const initialState = {
  data: {
    invoice_summary: {}
  },
  loading: true,
  error: ''
};

const InvoiceDetailsSlice = createSlice({
  name: 'invoice-details',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInvoiceDetails.pending, (state) => {
        // state.data = {};
        state.loading = true;
      })
      .addCase(getInvoiceDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.error = null;
          state.data = action?.payload || {
            invoice_summary: {}
          };
        }
      })
      .addCase(getInvoiceDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = {
          invoice_summary: {}
        };
      });
  }
});

export default InvoiceDetailsSlice.reducer;
