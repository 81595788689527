import { createSlice } from '@reduxjs/toolkit';
import { getCostCenters } from '../../api/api';

const initialState = {
  data: {},
  loading: false,
  error: ''
};

const CostCentersSlice = createSlice({
  name: 'cost_centers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCostCenters.pending, (state) => {
        state.data = {};
        state.loading = true;
      })
      .addCase(getCostCenters.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getCostCenters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.data = {};
      });
  }
});

export default CostCentersSlice.reducer;
