import axios from 'axios';
import { configuration } from 'services/baseApiService';
import { v4 as uuidv4 } from 'uuid';
const requestId = uuidv4();

const instance = axios.create();
const loginDetails = JSON.parse(localStorage.getItem('userDetails'));
const tenantName = loginDetails?.user?.customer?.id;

const refreshAuthToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post(`${configuration.newApiBaseUrl}token/refresh`, '', {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
        'Cache-Control': 'no-cache',
        'Tenant-Name': tenantName
      }
    });

    const { jwtToken, refreshToken: newRefreshToken } = response.data;
    localStorage.setItem('jwtToken', jwtToken);
    localStorage.setItem('refreshToken', newRefreshToken);

    return Promise.resolve(jwtToken);
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw new Error('Token refresh failed');
  }
};

let isRefreshing = false;
let refreshTokenPromise = null;

instance.interceptors.request.use(
  (config) => {
    const header = config.headers;
    const tenant = localStorage.getItem('tenantId')

    if ( tenant ) {
      header['X-Tenant-ID'] = tenant;
    }

    header['X-Request-ID'] = requestId;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
instance.interceptors.response.use(
  (res) => {
    return res;
  },

  async (error) => {
    try {
      const originalRequest = error?.config;
      const status = error?.response?.status;

      // console.log('originalRequest', originalRequest);
      // console.log('error:>> ', error);
      // console.log('error.response.status :>> ', error.response);

      if (status === 400) {
        console.error('error.response.data :>> ', error.response.data);
      }
      //expired refresh token
      if ((status === 401 || status === 403) && originalRequest.url.includes('refresh')) {
        localStorage.clear();
        sessionStorage.clear();

        window.location.href = '/login';
        return false;
      }
      //expired JWT token
      if ((status === 401 || status === 403) && !originalRequest._retry && !originalRequest?.url?.includes('login')) {
        if (!isRefreshing && localStorage.getItem('refreshToken')) {
          isRefreshing = true;
          localStorage.removeItem('persist:root');
          refreshTokenPromise = refreshAuthToken(); // Assuming refreshAuthToken returns a promise
        }

        return refreshTokenPromise
          .then((newToken) => {
            isRefreshing = false;
            axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            originalRequest._retry = true;
            return axios(originalRequest);
          })
          .catch((refreshError) => {
            console.error('refresh err', refreshError);
            localStorage.clear();
            const currentUrl = window.location.href;
            const timeStamp = new Date()

            sessionStorage.setItem('lastLoginUrl', currentUrl);
            sessionStorage.setItem('lastLoginTimeStamp', timeStamp);

            window.location.href = '/login';
            
            return Promise.reject(refreshError);
          });
      }
      return Promise.reject(error);
    } catch (error) {
      console.error('error', error);
    }
  }
);

export default instance;
